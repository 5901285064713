import { yupResolver } from '@hookform/resolvers/yup';
import { ResponsiveStyleValue } from '@mui/system';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { useOryUserLogin } from '../../../../ory/hooks/oryUserLogin/useOryUserLogin';
import { FormFieldNames, getMFAFormValidationSchema, IMFAFormData } from './validationScheme';

export interface IUseMFAForm {
  formData: UseFormReturn<IMFAFormData>;
  buttonDirection: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  buttonSpacing: number;
  formSubmit: (data: IMFAFormData) => void;
  isOryApiFetching: boolean;
}

export interface IUseMFAFormProps {
  onSuccess?: () => void;
}

export function useMFAForm(props: IUseMFAFormProps) {
  const { formatMessage } = useIntl();
  const { downSm } = useBreakpoints();
  const { finishLoginWithSecondFactor, isOryApiFetching } = useOryUserLogin();

  const formData = useForm<IMFAFormData>({
    defaultValues: { totpCode: '' },
    mode: 'onChange',
    resolver: yupResolver(getMFAFormValidationSchema(formatMessage)),
  });

  const buttonsDirection = (downSm ? 'column' : 'row') as ResponsiveStyleValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;
  const buttonsSpacing = downSm ? 2 : 1;

  const formSubmit = async (data: IMFAFormData) => {
    
    await finishLoginWithSecondFactor(
      { totp: data.totpCode },
      { fieldName: FormFieldNames.TotpCode, formData },
      props.onSuccess
    );
  };

  return {
    buttonsDirection,
    buttonsSpacing,
    formData,
    formSubmit,
    isOryApiFetching,
  };
}
