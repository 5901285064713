import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useOryUserPrivilegedSessionRefresh } from '../../hooks/oryPrivilegedSession/useOryUserPrivilegedSessionRefresh';
import { IUseOryResponseParser, useOryResponseParser } from '../../hooks/oryResponseParser';
import { OryContext } from '../../types';
import {
  defaultValues,
  FormFieldNames,
  getMFARecoveryCodesRefreshSessionFormValidationScheme,
  IMFARecoveryCodesRereshSessionFormData,
} from './validationSchema';

export interface IUseMFARecoveryCodesRefreshSession {
  refreshOrySession: (values: IMFARecoveryCodesRereshSessionFormData) => Promise<void>;
  formData: UseFormReturn<IMFARecoveryCodesRereshSessionFormData, any, undefined>;
  isOryApiFetching: boolean;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
}

export interface IUseMFARecoveryCodesRefreshSessionProps {
  onSuccess: () => void;
}

export function useMFARecoveryCodesRefreshSessionModal(
  props: IUseMFARecoveryCodesRefreshSessionProps
): IUseMFARecoveryCodesRefreshSession {
  const { clearFormAlertMessage, getFormAlertMessage } = useOryResponseParser();
  const { formatMessage } = useIntl();

  const formData = useForm<IMFARecoveryCodesRereshSessionFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getMFARecoveryCodesRefreshSessionFormValidationScheme(formatMessage, true)),
  });
  const { isOryApiFetching, refreshSession } = useOryUserPrivilegedSessionRefresh({
    context: OryContext.MFASettings,
    validationField: { fieldName: FormFieldNames.CurrentPassword, formData },
  });

  const refreshOrySession = async (values: IMFARecoveryCodesRereshSessionFormData) => {
    clearFormAlertMessage();
    if (values.currentPassword) {
      const result = await refreshSession(values.currentPassword);
      if (!result) {
        return;
      }
      props.onSuccess();
    }
  };

  return {
    formData,
    getFormAlertMessage,
    isOryApiFetching,
    refreshOrySession,
  };
}
