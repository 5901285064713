import { Edit, MailOutlined } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import Person from '@mui/icons-material/Person';
import { IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getOryLoginStatusSelector } from '../../modules/auth/selectors';
import { selectors } from '../../modules/user';
import { IUserDetail } from '../../modules/user/store';
import { MFASettingsButtons } from '../../ory/components/MFASettingsButtons';
import { OryLoginStatus } from '../../ory/types';
import { IconBox } from '../IconBox';
import Loader from '../Loader';
import messages from './messages';
import { Newsletter } from './Newsletter';
import styles from './styles';

export interface IUserInfoBoxProps {
  canChangePassword: boolean;
  loading: boolean;
  onShowEditPasswordModal: () => void;
  onShowEditUserModal: (user: IUserDetail) => void;
  onShowInstallAppModal?: () => void;
  onShowSetLanguageModal?: () => void;
  user: IUserDetail;
}

export function UserInfoBox({
  canChangePassword,
  loading,
  onShowEditPasswordModal,
  onShowEditUserModal,
  onShowInstallAppModal,
  onShowSetLanguageModal,
  user,
}: IUserInfoBoxProps) {
  const { pathname } = useLocation();
  const isCompanyOrSiteAdmin = useSelector(selectors.isCompanyOrSiteAdmin);
  const oryLoginStatus = useSelector(getOryLoginStatusSelector);
  const isMFAButtonVisible =
    canChangePassword && isCompanyOrSiteAdmin && oryLoginStatus === OryLoginStatus.SessionConfirmed;

  return (
    <Paper sx={styles.root}>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={styles.infoBox} data-testid="user-box">
          <Person sx={styles.icon} />
          <Box sx={styles.infoBoxUser}>
            <Stack sx={styles.infoBoxUserDetailWrapper}>
              <Stack sx={styles.infoBoxUserDetail} gap={1}>
                <Stack gap={1} direction={'row'}>
                  <Typography gutterBottom sx={styles.infoBoxUserDetailName} variant="h2" data-testid="user-fullname">
                    {`${user.firstName} ${user.lastName}`.trim()}
                  </Typography>
                  <Stack justifyContent={'center'}>
                    <Tooltip title={<FormattedMessage {...messages.UserInfoBoxEditProfile} />}>
                      <IconButton onClick={() => onShowEditUserModal(user)} data-testid="edit-profile">
                        <Edit color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>

                <IconBox icon={<MailOutlined color="secondary" />} withoutMargin>
                  <Typography color="secondary">{user.email}</Typography>
                </IconBox>
                <Newsletter newsletters={user?.newsletters || false} />
              </Stack>

              <Stack sx={styles.infoBoxUserButtons} direction={'column'} alignItems={'flex-start'} gap={1}>
                {!pathname.includes('admins') && (
                  <Button
                    color="primary"
                    onClick={onShowSetLanguageModal}
                    data-testid="change-language"
                    startIcon={<LanguageIcon />}
                  >
                    <FormattedMessage {...messages.UserInfoBoxChangeLanguage} />
                  </Button>
                )}
                {canChangePassword && (
                  <Button
                    color="primary"
                    onClick={onShowEditPasswordModal}
                    data-testid="change-password"
                    startIcon={<LockOutlinedIcon />}
                  >
                    <FormattedMessage {...messages.UserInfoBoxChangePassword} />
                  </Button>
                )}

                {isMFAButtonVisible && <MFASettingsButtons />}

                {!isCompanyOrSiteAdmin && (
                  <Button
                    color="primary"
                    onClick={onShowInstallAppModal}
                    startIcon={<MobileFriendlyOutlinedIcon />}
                    data-testid="install-app"
                  >
                    <FormattedMessage {...messages.UserInfoBoxInstallApp} />
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
