import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilledInfoBar } from '../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { RouterLink } from '../../../ResetPasswordPage/components/Link/PrimaryLink';
import messages from './messages';
import { useMFARecoveryForm } from './useMFARecoveryForm';
import { FormFieldNames } from './validationSchema';

export interface IMFARecoveryProps {
  onBack: () => void;
  onSuccess?: () => void;
}

export function MFARecoveryForm(props: IMFARecoveryProps) {
  const { buttonsDirection, buttonsSpacing, formData, formSubmit, isOryApiFetching } = useMFARecoveryForm({
    onSuccess: props.onSuccess,
  });
  const { formatMessage } = useIntl();

  return (
    <Box sx={{ mt: -2 }}>
      <Stack gap={2}>
        <FilledInfoBar>
          <Typography variant="body2" component="div">
            <FormattedMessage
              {...messages.ImsTwoFactorAuthenticationCantAccessAlertTextV2}
              values={{
                b: (chunks) => <b>{chunks}</b>,
                link: (chunks) => (
                  <Link href="https://www.2n.com/en-GB/support/#verticaltabscontainer-do-you-need-support-or-help">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Typography>
        </FilledInfoBar>
        <FormProvider {...formData}>
          <form onSubmit={formData.handleSubmit(formSubmit)}>
            <OutlinedTextField
              id="totp-recovery"
              label={formatMessage(messages.ImsTwoFactorAuthenticationCantAccessRecoveryCode)}
              name={FormFieldNames.TotpRecoveryCode}
              required
              fullWidth
              helperText={formatMessage(messages.ImsTwoFactorAuthenticationCantAccessRecoveryCodeHelperText)}
            />

            <Stack
              direction={buttonsDirection}
              spacing={buttonsSpacing}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <RouterLink onClick={() => props.onBack()} to="">
                <FormattedMessage {...messages.ImsTwoFactorAuthenticationCantAccessBack} />
              </RouterLink>

              <Button
                startIcon={<ArrowForward />}
                disabled={!formData.formState.isValid || isOryApiFetching}
                color="primary"
                id="TOTP-submit"
                size="large"
                type="submit"
                variant="contained"
              >
                <FormattedMessage {...messages.ImsTwoFactorAuthenticationCantAccessRecoveryCodeSubmit} />
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Box>
  );
}
