import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { useOrySettingsApi } from '../../hooks/oryApi';
import { useOryResponseParser } from '../../hooks/oryResponseParser';
import { OryErrorNamedMessageId } from '../../types';
import { IOryMFASettings } from '../../utils/MFA';

export interface IUseMFASettingsButton {
  fetchMFASettings: () => Promise<void>;
  openMFAModal: () => void;
  isOryApiFetching: boolean;
  oryMFA: IOryMFASettings | undefined;
}

export interface IUseMFASettingsButtonProps {
  openMFAUnlinkModal: () => void;
  openMFASetupModal: () => void;
}

export function useMFASettingsButtons({
  openMFASetupModal,
  openMFAUnlinkModal,
}: IUseMFASettingsButtonProps): IUseMFASettingsButton {
  const { getMFASettings, isOryApiFetching } = useOrySettingsApi();
  const [oryMFA, setOryMFA] = useState<IOryMFASettings | undefined>();
  const { parseOryResponseWithData } = useOryResponseParser();
  const navigate = useNavigate();

  const openMFAModal = () => {
    if (oryMFA?.isEnabled) {
      openMFAUnlinkModal();
    } else if (oryMFA?.setupConfiguration) {
      openMFASetupModal();
    }
  };

  const fetchMFASettings = async () => {
    const response = await getMFASettings();
    parseOryResponseWithData({
      onError: (error) => {
        if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
          navigate(PATHS.LOGOUT);
        }
      },
      onSuccess: (data) => {
        setOryMFA(data);
      },
      response,
    });
  };

  return { fetchMFASettings, isOryApiFetching, openMFAModal, oryMFA };
}
