import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconBox } from '../IconBox';
import messages from './messages';

export interface INewsletterProps {
  newsletters: boolean;
}

export function Newsletter({ newsletters }: INewsletterProps) {
  const icon = newsletters ? <CheckCircle color="success" /> : <Cancel color="error" />;
  const message = newsletters
    ? messages.UserInfoBoxReceivingNewslettersEnabled
    : messages.UserInfoBoxReceivingNewslettersDisabled;

  return (
    <Box>
      <IconBox icon={icon} withoutMargin>
        <FormattedMessage {...message} />
      </IconBox>
    </Box>
  );
}
