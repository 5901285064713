import { AxiosError } from 'axios';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { LoginMFAAssuranceLevel, OryApiRequestCancelTokenId } from '../../../config/const';
import { oryApi } from '../../../config/oryFrontendApi';
import { processOryApiError } from '../../../errors/errorParser';
import { IOryGeneralError, OryCancelableResponse, OryContext, OryResponse } from '../../../types';
import { getCSRFToken } from '../../../utils/CSRFToken';
import { getLoginFlowUpdateBody } from '../../../utils/loginFlowBody';
import { hasResponseOKStatus } from '../../../utils/responseStatus';
import { ILoginToOryProps } from './useOryLoginApi';

export async function loginToOry(
  params: ILoginToOryProps,
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    const isMFALogin = !!params.totp || !!params.totpRecoveryCode;
    const loginFlowInit = await oryApi.createBrowserLoginFlow({
      aal: isMFALogin ? LoginMFAAssuranceLevel : undefined,
      refresh: params.sessionRefresh,
    });
    const csrfToken = getCSRFToken(loginFlowInit.data);
    const loginFlow = await oryApi.updateLoginFlow({
      flow: loginFlowInit.data.id,
      updateLoginFlowBody: getLoginFlowUpdateBody(params, csrfToken),
    });
    fetchingState(false);
    if (hasResponseOKStatus(loginFlow)) {
      return true;
    }

    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: params.context || OryContext.Login,
      dispatch,
      formatMessage,
    });
  }
}

export async function getCurrentUserOrySession(
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage'],
  params?: { cancelToken?: any }
): Promise<OryCancelableResponse> {
  fetchingState(true);
  try {
    const session = await oryApi.toSession(undefined, { cancelToken: params?.cancelToken });

    fetchingState(false);

    if (hasResponseOKStatus(session)) {
      return true;
    }

    return false;
  } catch (e: any) {
    if (e && e.message && e.message === OryApiRequestCancelTokenId) {
      return undefined;
    }
    fetchingState(false);

    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
      hideNotificationErrors: true,
    });
  }
}

export async function logoutFromOry(
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage'],
  params?: { cancelToken?: any }
): Promise<OryCancelableResponse> {
  fetchingState(true);
  try {
    const logoutFlowInit = await oryApi.createBrowserLogoutFlow(undefined, { cancelToken: params?.cancelToken });
    const logoutFlow = await oryApi.updateLogoutFlow(
      { token: logoutFlowInit.data.logout_token },
      { cancelToken: params?.cancelToken }
    );

    fetchingState(false);

    if (hasResponseOKStatus(logoutFlow)) {
      return true;
    }

    return false;
  } catch (e: any) {
    if (e && e.message && e.message === OryApiRequestCancelTokenId) {
      return undefined;
    }
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: OryContext.Logout,
      dispatch,
      formatMessage,
      hideNotificationErrors: true,
    });
  }
}
