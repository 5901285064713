import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArrayEmpty } from '../../helpers/array';
import { isLoadingSelector } from '../../modules/auth/selectors';
import { loadGlobalFeatures } from '../../modules/common/actions';
import { getGlobalFeatures, isFetchingFeatures } from '../../modules/common/selectors';
import { PublicFormWrapper } from '../_components/PublicFormWrapper';
import { MFAForm } from '../LoginPage/components/MFAForm/MFAForm';
import { MFARecoveryForm } from '../LoginPage/components/MFARecoveryForm/MFARecoveryForm';
import { EmailForm } from './components/EmailForm/EmailForm';
import { NewPasswordForm } from './components/NewPasswordForm';
import { RecoveryCodeForm } from './components/RecoveryCodeForm/RecoveryCodeForm';
import { ResetPasswordFormStep } from './types';
import { useResetPasswordPage } from './useResetPasswordPage';

export function ResetPasswordPage() {
  const isFetching = useSelector(isLoadingSelector);
  const globalFeatures = useSelector(getGlobalFeatures);
  const isLoadingGlobalFeatures = useSelector(isFetchingFeatures);
  const dispatch = useDispatch();

  const {
    email,
    emailFormSubmit,
    formTitle,
    isOryApiFetching,
    newPasswordFormSuccess,
    oryRecoveryFlowData,
    recoveryCodeFormSuccess,
    recoveryFlowRestart,
    recoveryWholeProcessRestart,
    resetPasswordFormStep,
    setResetPasswordFormStep,
  } = useResetPasswordPage();

  const isEmailFormActive = resetPasswordFormStep === ResetPasswordFormStep.EmailForm;
  const isRecoveryCodeFormActive =
    resetPasswordFormStep === ResetPasswordFormStep.ORYRecoveryCodeForm && oryRecoveryFlowData;
  const isMFAFormActive = resetPasswordFormStep === ResetPasswordFormStep.MFAForm;
  const isMFARecoveryFormActive = resetPasswordFormStep === ResetPasswordFormStep.MFARecovery;
  const isNewPasswordFormActive = resetPasswordFormStep === ResetPasswordFormStep.ORYNewPasswordForm;

  useEffect(() => {
    if (isArrayEmpty(globalFeatures) && !isLoadingGlobalFeatures) {
      dispatch(loadGlobalFeatures());
    }
  }, []);

  return (
    <PublicFormWrapper title={formTitle}>
      {isEmailFormActive && (
        <EmailForm initEmail={email} isDisabled={isFetching || isOryApiFetching} onSubmit={emailFormSubmit} />
      )}

      {isRecoveryCodeFormActive && (
        <RecoveryCodeForm
          recoveryFlowData={oryRecoveryFlowData}
          email={email}
          onSuccess={recoveryCodeFormSuccess}
          onRecoveryProcessRestart={recoveryWholeProcessRestart}
          onRecoveryFlowRestart={recoveryFlowRestart}
        />
      )}
      {isMFAFormActive && (
        <MFAForm
          onMFARecovery={() => {
            setResetPasswordFormStep(ResetPasswordFormStep.MFARecovery);
          }}
          onSuccess={() => {
            setResetPasswordFormStep(ResetPasswordFormStep.ORYNewPasswordForm);
          }}
        />
      )}
      {isMFARecoveryFormActive && (
        <MFARecoveryForm
          onBack={() => {
            setResetPasswordFormStep(ResetPasswordFormStep.MFAForm);
          }}
          onSuccess={() => {
            setResetPasswordFormStep(ResetPasswordFormStep.ORYNewPasswordForm);
          }}
        />
      )}
      {isNewPasswordFormActive && (
        <NewPasswordForm
          onSuccess={newPasswordFormSuccess}
          onRecoveryProcessRestart={() => {
            recoveryWholeProcessRestart();
          }}
        />
      )}
    </PublicFormWrapper>
  );
}
