import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { OryResponse, OryResponseWithData } from '../../../types';
import { IOryMFARecoveryCodes, IOryMFASettings } from '../../../utils/MFA';
import {
  confirmRecoveryCodes,
  disableRecoveryCodes,
  getMFASettings,
  getRecoveryCodesSettings,
  IConfirmRecoveryCodesParams,
  IDisableMFARecoveryCodesParams,
  IRegenerateRecoveryCodesParams,
  ISetMFASettingsParams,
  IUnlinkMFASettingsParams,
  regenerateRecoveryCodes,
  setMFASettings,
  unlinkMFASettings,
  updatePassword,
} from './settingsApi';

export interface IChangePasswordProps {
  password: string;
}

export interface IUseSettingsApi {
  updatePassword: (props: IChangePasswordProps) => Promise<OryResponse>;
  disableRecoveryCodes: (props: IDisableMFARecoveryCodesParams) => Promise<OryResponse>;
  confirmRecoveryCodes: (props: IConfirmRecoveryCodesParams) => Promise<OryResponse>;
  regenerateRecoveryCodes: (
    props: IRegenerateRecoveryCodesParams
  ) => Promise<OryResponseWithData<IOryMFARecoveryCodes>>;
  getMFASettings: () => Promise<OryResponseWithData<IOryMFASettings>>;
  setMFASettings: (props: ISetMFASettingsParams) => Promise<OryResponse>;
  unlinkMFASettings: (props: IUnlinkMFASettingsParams) => Promise<OryResponse>;
  getRecoveryCodesSettings: () => Promise<OryResponseWithData<IOryMFARecoveryCodes>>;
  isOryApiFetching: boolean;
}

export function useOrySettingsApi(): IUseSettingsApi {
  const [isFetching, setIsFetching] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return {
    confirmRecoveryCodes: (props) => confirmRecoveryCodes(props, setIsFetching, dispatch, formatMessage),
    disableRecoveryCodes: (props) => disableRecoveryCodes(props, setIsFetching, dispatch, formatMessage),
    getMFASettings: () => getMFASettings(setIsFetching, dispatch, formatMessage),
    getRecoveryCodesSettings: () => getRecoveryCodesSettings(setIsFetching, dispatch, formatMessage),
    isOryApiFetching: isFetching,
    regenerateRecoveryCodes: (props) => regenerateRecoveryCodes(props, setIsFetching, dispatch, formatMessage),
    setMFASettings: (props) => setMFASettings(props, setIsFetching, dispatch, formatMessage),
    unlinkMFASettings: (props) => unlinkMFASettings(props, setIsFetching, dispatch, formatMessage),
    updatePassword: (props) => updatePassword(props, setIsFetching, dispatch, formatMessage),
  };
}
