import { SettingsFlow } from '@ory/client';
import { isArrayEmpty } from '../../helpers/array';
import { containsOneOfSpecificErrors } from '../errors/errorParser';
import { OryErrorMessage, OryErrorNamedMessageId } from '../types';
import { getCSRFToken } from './CSRFToken';

export interface IOryMFASettings {
  setupConfiguration?: {
    qrCodeBase64: string;
    totpSecret: string;
  };
  isEnabled: boolean;
  flowId: string;
  csrfToken: string;
}

export interface IOryMFARecoveryCodesSettings {
  isRevealAvailable: boolean;
  isDisableAvailable: boolean;
  isGenerateAvailable: boolean;
  flowId: string;
  csrfToken: string;
}

export interface IOryMFARecoveryCodes {
  recoveryCodes: IRecoveryCode[];
  isDisableAvailable: boolean;
  isRegenerateAvailable: boolean;
  isConfirmAvailable: boolean;
  flowId: string;
  csrfToken: string;
  hasBeenRegenerated?: boolean;
}

export interface IRecoveryCode {
  code: string;
  wasUsed: boolean;
}

export function getRecoveryCodesSetupSettings(response: SettingsFlow): IOryMFARecoveryCodesSettings {
  const recoveryCodesNodes = response.ui.nodes.filter((node) => node.group === 'lookup_secret');
  let isRevealAvailable = false;
  let isDisableAvailable = false;
  let isRegenerateAvailable = false;

  const csrfToken = getCSRFToken(response);
  const flowId = response.id;

  if (recoveryCodesNodes) {
    recoveryCodesNodes.forEach((node) => {
      if (node.attributes.node_type === 'input' && node.attributes.name === 'lookup_secret_reveal') {
        isRevealAvailable = true;
      }
      if (node.attributes.node_type === 'input' && node.attributes.name === 'lookup_secret_disable') {
        isDisableAvailable = true;
      }
      if (node.attributes.node_type === 'input' && node.attributes.name === 'lookup_secret_regenerate') {
        isRegenerateAvailable = true;
      }
    });
  }
  return { csrfToken, flowId, isDisableAvailable, isGenerateAvailable: isRegenerateAvailable, isRevealAvailable };
}

export function getRecoveryCodes(response: SettingsFlow): IOryMFARecoveryCodes {
  const recoveryCodesNodes = response.ui.nodes.filter((node) => node.group === 'lookup_secret');
  const recoveryCodes: IRecoveryCode[] = [];
  let isDisableAvailable = false;
  let isRegenerateAvailable = false;
  let isConfirmAvailable = false;

  const csrfToken = getCSRFToken(response);
  const flowId = response.id;

  if (recoveryCodesNodes) {
    recoveryCodesNodes.forEach((node) => {
      if (
        node.attributes.node_type === 'text' &&
        node.attributes.id === 'lookup_secret_codes' &&
        node.attributes.text &&
        node.attributes.text.type === 'info' &&
        node.attributes.text.context
      ) {
        const secrets = (
          node.attributes.text.context as { secrets: { text: string; context: { used_at?: string } }[] | undefined }
        ).secrets;
        if (!isArrayEmpty(secrets)) {
          secrets?.forEach((context) => {
            recoveryCodes.push({
              code: context.text,
              wasUsed: !!context.context.used_at,
            });
          });
        }
      }
      if (node.attributes.node_type === 'input' && node.attributes.name === 'lookup_secret_disable') {
        isDisableAvailable = true;
      }
      if (node.attributes.node_type === 'input' && node.attributes.name === 'lookup_secret_confirm') {
        isConfirmAvailable = true;
      }
      if (node.attributes.node_type === 'input' && node.attributes.name === 'lookup_secret_regenerate') {
        isRegenerateAvailable = true;
      }
    });
  }
  return { csrfToken, flowId, isConfirmAvailable, isDisableAvailable, isRegenerateAvailable, recoveryCodes };
}

export function getMFASetup(response: SettingsFlow): IOryMFASettings {
  const totpNodes = response.ui.nodes.filter((node) => node.group === 'totp');
  let qrCodeBase64;
  let totpSecret;
  let setupConfiguration;
  let isEnabled = false;

  const csrfToken = getCSRFToken(response);
  const flowId = response.id;

  if (totpNodes) {
    totpNodes.forEach((node) => {
      if (node.attributes.node_type === 'img' && node.attributes.id === 'totp_qr') {
        qrCodeBase64 = node.attributes.src;
      }

      if (node.attributes.node_type === 'text' && node.attributes.id === 'totp_secret_key') {
        totpSecret = node.attributes.text.text;
      }

      if (node.attributes.node_type === 'input' && node.attributes.name === 'totp_unlink') {
        isEnabled = true;
      }
    });

    if (qrCodeBase64 && totpSecret) {
      setupConfiguration = {
        qrCodeBase64,
        totpSecret,
      };
    }
  }
  return { csrfToken, flowId, isEnabled, setupConfiguration };
}

export function isSecondFactorNeeded(error: OryErrorMessage | undefined, onSecondFactor: () => void) {
  if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Browser_location_change_required])) {
    onSecondFactor();
  }
}
