import CheckCircle from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconBox } from '../../../../components/IconBox';
import { MOBILE_VIDEO_STATUS } from '../../../../config/consts';
import messages from './messages';

const SiteStatusSummary = ({ status }) => (
  <IconBox icon={<CheckCircle color="success" />}>
    <Typography>
      {status === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE && (
        <FormattedMessage {...messages.siteStatusSummaryInfoTrialActive} values={{ br: <br /> }} />
      )}
      {status === MOBILE_VIDEO_STATUS.TRIAL_EXPIRED && (
        <FormattedMessage {...messages.siteStatusSummaryInfoTrialExpired} values={{ br: <br /> }} />
      )}
      {status === MOBILE_VIDEO_STATUS.STANDARD_EXPIRED && (
        <FormattedMessage {...messages.siteStatusSummaryInfoStandardExpired} values={{ br: <br /> }} />
      )}
    </Typography>
  </IconBox>
);

SiteStatusSummary.propTypes = {
  status: PropTypes.oneOf([
    MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
    MOBILE_VIDEO_STATUS.TRIAL_EXPIRED,
    MOBILE_VIDEO_STATUS.STANDARD_EXPIRED,
  ]).isRequired,
};

export default SiteStatusSummary;
