import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsTwoFactorAuthenticationCantAccessAlertTextV2: {
    defaultMessage: `The authenticator is the mobile app you set up when enabling two-factor authentication for login.
<p>Some authenticator apps also offer <b>browser access</b>, which can help if you’ve lost your phone.</p>
<p>If you have <b>recovery codes</b> enabled, you can use one to log in.</p>
<p>For further assistance, <link>contact our support team</link>.</p>`,
    id: 'ims.twoFactorAuthentication.cantAccess.alertText.v2',
  },
  ImsTwoFactorAuthenticationCantAccessBack: {
    defaultMessage: 'Back to two-factor authentication',
    id: 'ims.twoFactorAuthentication.cantAccess.back',
  },
  ImsTwoFactorAuthenticationCantAccessRecoveryCode: {
    defaultMessage: 'Recovery code',
    id: 'ims.twoFactorAuthentication.cantAccess.recoveryCode',
  },
  ImsTwoFactorAuthenticationCantAccessRecoveryCodeHelperText: {
    defaultMessage: 'Enter your recovery code if you’ve generated one previously',
    id: 'ims.twoFactorAuthentication.cantAccess.recoveryCode.helperText',
  },
  ImsTwoFactorAuthenticationCantAccessRecoveryCodeSubmit: {
    defaultMessage: 'Use code',
    id: 'ims.twoFactorAuthentication.cantAccess.recoveryCode.submit',
  },
});
