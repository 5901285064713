import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { useOrySettingsApi } from '../../hooks/oryApi';
import { useOryResponseParser } from '../../hooks/oryResponseParser';
import { OryErrorNamedMessageId } from '../../types';
import { IOryMFARecoveryCodes } from '../../utils/MFA';

export interface IUseMFARecoveryCodesSettingsButton {
  fetchMFARecoveryCodesSettings: () => Promise<void>;
  isOryApiFetching: boolean;
  oryMFARecoveryCodes: IOryMFARecoveryCodes | undefined;
  onCodesRegenerate: (newConfiguration: IOryMFARecoveryCodes) => void;
}

export interface IUseMFARecoveryCodesSettingsButtonProps {
  openMFARecoveryCodesModal: () => void;
  openSessionRefreshModal: () => void;
}

export function useMFARecoveryCodesSettingsButton({
  openMFARecoveryCodesModal,
  openSessionRefreshModal,
}: IUseMFARecoveryCodesSettingsButtonProps): IUseMFARecoveryCodesSettingsButton {
  const { getRecoveryCodesSettings, isOryApiFetching } = useOrySettingsApi();
  const [oryMFARecoveryCodes, setOryMFARecoveryCodes] = useState<IOryMFARecoveryCodes | undefined>();
  const { parseOryResponseWithData } = useOryResponseParser();
  const navigate = useNavigate();

  const fetchMFARecoveryCodesSettings = async () => {
    const response = await getRecoveryCodesSettings();
    parseOryResponseWithData({
      onError: (error) => {
        if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
          navigate(PATHS.LOGOUT);
        }
        if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
          openSessionRefreshModal();
        }
      },
      onSuccess: (data) => {
        setOryMFARecoveryCodes(data);
        openMFARecoveryCodesModal();
      },
      response,
    });
  };

  return {
    fetchMFARecoveryCodesSettings,
    isOryApiFetching,
    onCodesRegenerate: (newConfiguration) => {
      newConfiguration.hasBeenRegenerated = true;
      setOryMFARecoveryCodes(newConfiguration);
    },
    oryMFARecoveryCodes,
  };
}
