import { Dialog, IActionButtonData } from '@2n/design-system';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { IDSModalBase } from '../designSystemUnexported';

export interface ITernaryButton extends IActionButtonData {
  isLoading?: boolean;
}

export interface IActionModalData extends IDSModalBase {
  icon: React.ReactNode;
  isPrimaryButtonDisabled: boolean;
  primaryButtonVariant?: IActionButtonData['variant'];
  isNotClosable?: boolean;
  ternaryButton?: ITernaryButton;
  isPrimaryButtonLoading?: boolean;
}

export function ActionModal(data: IActionModalData) {
  return (
    <Dialog
      closeOnlyByButton={data.isNotClosable}
      hideCloseButton={data.isNotClosable}
      footerOptions={{
        primaryButton:
          data.primaryButtonAction && data.primaryButtonText !== undefined
            ? {
                action: data.primaryButtonAction,
                icon: {
                  icon: data.isPrimaryButtonLoading ? <CircularProgress size={20} /> : data.icon,
                  iconPosition: 'start',
                },
                isDisabled: data.isPrimaryButtonDisabled,
                text: data.primaryButtonText,
                variant: data.primaryButtonVariant || 'contained',
              }
            : undefined,
        secondaryButton:
          data.secondaryButtonText !== undefined
            ? {
                action: data.onClose,
                color: 'secondary',
                text: data.secondaryButtonText,
                variant: 'text',
              }
            : undefined,
        ternaryButton: data.ternaryButton
          ? {
              ...data.ternaryButton,
              icon: data.ternaryButton.isLoading
                ? { icon: <CircularProgress size={20} />, iconPosition: 'start' }
                : data.ternaryButton.icon,
            }
          : undefined,
      }}
      muiDialogProps={data.muiDialogProps}
      onClose={data.onClose}
      open={!!data.open}
      title={data.title}
    >
      <Box data-testid={data.testId}>{data.children}</Box>
    </Dialog>
  );
}

export default ActionModal;
