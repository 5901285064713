import { defineMessages } from 'react-intl';

export default defineMessages({
  SharedLabelCurrentPassword: {
    defaultMessage: 'Current password',
    id: 'shared.label.currentPassword',
  },
  TwoFactorRecoveryCodesModalCancel: {
    defaultMessage: 'Cancel',
    id: 'twoFactorRecoveryCodes.modal.cancel',
  },
  TwoFactorRecoveryCodesModalCheckbox: {
    defaultMessage: 'I have copied the codes and saved them safely',
    id: 'twoFactorRecoveryCodes.modal.checkbox',
  },
  TwoFactorRecoveryCodesModalConfirmCodes: {
    defaultMessage: 'CONFIRM CODES',
    id: 'twoFactorRecoveryCodes.modal.confirmCodes',
  },

  TwoFactorRecoveryCodesModalDeleteCodes: {
    defaultMessage: 'DELETE CODES',
    id: 'twoFactorRecoveryCodes.modal.deleteCodes',
  },

  TwoFactorRecoveryCodesModalDontUseCodes: {
    defaultMessage: 'Don’t use codes',
    id: 'twoFactorRecoveryCodes.modal.dontUseCodes',
  },
  TwoFactorRecoveryCodesModalGenerateCodes: {
    defaultMessage: 'Generate new codes',
    id: 'twoFactorRecoveryCodes.modal.generateCodes',
  },
  TwoFactorRecoveryCodesModalInfoAlert: {
    defaultMessage: 'For maximum security, regenerate these codes periodically.{br}You can do this anytime.',
    id: 'twoFactorRecoveryCodes.modal.infoAlert',
  },
  TwoFactorRecoveryCodesModalSessionExpired: {
    defaultMessage: 'Your session has expired. Please, enter your password.',
    id: 'twoFactorRecoveryCodes.modal.sessionExpired',
  },
  TwoFactorRecoveryCodesModalText1: {
    defaultMessage:
      'These recovery codes let you complete two-factor authentication if you lose access to your authentication app. Keep them safe.',
    id: 'twoFactorRecoveryCodes.modal.text1',
  },
  TwoFactorRecoveryCodesModalText2: {
    defaultMessage: 'You need to confirm the codes before they become active.',
    id: 'twoFactorRecoveryCodes.modal.text2',
  },
  TwoFactorRecoveryCodesModalTitle: {
    defaultMessage: 'Two-factor recovery codes',
    id: 'twoFactorRecoveryCodes.modal.title',
  },

  TwoFactorRecoveryCodesSuccessDelete: {
    defaultMessage: 'Codes deleted successfully',
    id: 'twoFactorRecoveryCodes.success.delete',
  },

  TwoFactorRecoveryCodesSuccessGenerate: {
    defaultMessage: 'Two-factor backup codes generated and confirmed successfully',
    id: 'twoFactorRecoveryCodes.success.generate',
  },
});
