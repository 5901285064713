import { Theme } from '@mui/material';

export default {
  icon: {
    backgroundColor: 'divider',
    borderRadius: '85px',
    color: 'common.white',
    fontSize: '85px',
    height: '85px',
    lineHeight: 1,
    mr: 3,
    p: 1,
    verticalAlign: 'middle',
    width: '85px',
  },
  infoBox: {
    alignItems: 'flex-start',
    display: {
      md: 'flex',
      sm: 'block',
    },
    flex: '0 0 100%',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  infoBoxUser: {
    width: '100%',
  },
  infoBoxUserButtons: {
    whiteSpace: { md: 'nowrap', xs: 'normal' },
    width: '100%',
  },
  infoBoxUserDetail: {
    width: '100%',
    wordBreak: 'break-word',
  },
  infoBoxUserDetailName: {
    color: 'rgba(0,0,0,.87)',
    fontSize: (theme: Theme) => ({
      md: '24px',
      xs: theme.typography.fontSize * 1.3,
    }),
    fontWeight: 400,
    mb: 0,
    pt: { md: '6px', xs: '10px' },
    textAlign: 'left',
  },
  infoBoxUserDetailWrapper: {
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    gap: {
      md: 2,
      xs: 3,
    },
  },
  root: {
    mb: 2,
    pb: 3,
    pt: 4,
    px: 4,
    textAlign: 'center',
  },
};
