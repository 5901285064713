import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DataGridSearchField, Title, UserModal, UserProfileDevicesDataGrid } from '../../components';
import NonClosingModal from '../../components/_DesignSystemWrappers/Modal/NonClosingModal';
import CreateCompanyDialog from '../../components/CreateCompanyModal';
import InstallAppModalContent from '../../components/InstallAppModalContent';
import NoCompanyBox from '../../components/NoCompanyBox';
import UserChangePasswordDialog from '../../components/UserChangePasswordDialog';
import UserLanguageDialog from '../../components/UserLanguageModal';
import { DEVICE_TYPE, SERVICE } from '../../config/devices';
import { changeFilter, dataGridProps, getDataGridSelector, useDataGrid } from '../../helpers/dataGrid';
import { useModal } from '../../hooks/useModal';
import authActions, { selectors as authSelectors } from '../../modules/auth';
import commonActions from '../../modules/common';
import deviceActions, { NAME as DEVICE_MODULE, selectors as deviceSelectors } from '../../modules/devices';
import { CONTEXT, selectors } from '../../modules/user';
import { ChangeOryUserPasswordModal as ChangeOryUserPasswordModalComponent } from '../../ory/components/ChangeOryPasswordModal';
import { useOryChangeProfileModalOpener } from '../../ory/hooks/oryChangeProfile';
import UserLoserDevice from '../UserLoserDevice/UserLoserDevice';
import getInstallAppModalProps from './helpers/getInstallAppModalProps';
import messages from './messages';
import { UserInfoBox } from '../../components/UserInfoBox';

const styles = {
  companyInfo: {
    '& span': {
      mr: 0.5,
    },
  },
  devicesTable: {
    '& .MuiGrid-grid-xs-10': {
      flexBasis: {
        sm: 0,
        xs: '100%',
      },
      maxWidth: {
        xs: '100%',
      },
    },
    my: 4,
  },
  whiteBackground: {
    backgroundColor: 'common.white',
  },
};

const UserProfile = ({
  devicesLoadedFirstTime,
  devicesTable,
  isCompanyOrSiteAdmin,
  isFirstLogin,
  isMarketer,
  onLoadDevices,
  onSearch,
  onSetFirstLogin,
  user,
  userAdminSites,
  usersCompanies,
}) => {
  useEffect(() => {
    if (isCompanyOrSiteAdmin) {
      onSetFirstLogin(false);
    }
  }, [isCompanyOrSiteAdmin]);

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadDevices,
    {
      context: CONTEXT.CURRENT_USER,
      filter: devicesTable.filter,
      noContextNeeded: true,
      order: devicesTable.order,
      page: devicesTable.page,
      rowsPerPage: devicesTable.rowsPerPage,
    },
    devicesTable.didInvalid
  );

  const { formatMessage } = useIntl();

  const [fulltext, setFulltext] = useState(devicesTable.filter.fulltext || '');
  const [editUserModal, toggleEditUserModal] = useState(false);
  const [installAppModal, setInstallAppModal] = useState({
    context: 'firstLogin',
    open: isFirstLogin,
  });

  const { Modal: UserChangePasswordModal, onOpen: onOpenUserChangePasswordModal } = useModal({
    Modal: UserChangePasswordDialog,
  });

  const { Modal: ChangeOryUserPasswordModal, onOpen: openChangeOryUserPasswordModal } = useModal({
    Modal: ChangeOryUserPasswordModalComponent,
  });

  const { Modal: CreateCompanyModal, onOpen: onOpenCreateCompanyModal } = useModal({
    Modal: CreateCompanyDialog,
  });

  const { Modal: UserLanguageModal, onOpen: onOpenUserLanguageModal } = useModal({
    Modal: UserLanguageDialog,
  });

  const { openChangeProfileModal: openChangePasswordModal } = useOryChangeProfileModalOpener({
    openModalForMy2nUser: onOpenUserChangePasswordModal,
    openModalForOryUser: openChangeOryUserPasswordModal,
  });

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, deviceActions.getUsersProfileDevicesRequest, {
      ...devicesTable,
      noContextNeeded: true,
    })(name, value);
  };

  const isUserLoser = useMemo(
    () => devicesTable.resultsTotal === 1 && devicesTable.data[0].type === DEVICE_TYPE.HIPMO,
    [devicesTable.resultsTotal, devicesTable.data[0]]
  );

  const hasUserLoserMobileVideo = useMemo(
    () => !!devicesTable.data[0]?.services[SERVICE.MOBILE_VIDEO],
    [(devicesTable.resultsTotal, devicesTable.data[0])]
  );

  const installAppModalProperties = getInstallAppModalProps(
    () => {
      setInstallAppModal({ ...installAppModal, open: false });
      onSetFirstLogin(false);
    },
    installAppModal.context,
    formatMessage
  );

  return (
    <>
      <Title heading={<FormattedMessage {...messages.userProfileTitle} />} />
      <UserInfoBox
        canChangePassword
        onShowEditPasswordModal={openChangePasswordModal}
        onShowEditUserModal={() => toggleEditUserModal(true)}
        onShowInstallAppModal={() => setInstallAppModal({ context: 'installApp', open: true })}
        onShowSetLanguageModal={onOpenUserLanguageModal}
        user={user}
      />
      {usersCompanies.length === 0 && <NoCompanyBox onOpenAddCompanyModal={onOpenCreateCompanyModal} />}
      {(isUserLoser && !hasUserLoserMobileVideo) ||
        (!isMarketer && devicesLoadedFirstTime && usersCompanies.length > 0 && (
          <Box sx={styles.devicesTable}>
            <Title
              heading={
                <FormattedMessage
                  {...(isUserLoser
                    ? messages.userProfileDevicesTableTitleOne
                    : messages.userProfileDevicesTableTitlePlural)}
                />
              }
              rightSide={
                !isUserLoser && (
                  <DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} sx={{ maxWidth: '230px' }} />
                )
              }
            />
            {isUserLoser ? (
              <UserLoserDevice
                device={devicesTable.data[0]}
                onShowInstallAppModal={() => setInstallAppModal({ context: 'installApp', open: true })}
              />
            ) : (
              <UserProfileDevicesDataGrid
                {...devicesTable}
                onOrderBy={onOrderBy}
                onRowsPerPageChange={onRowsPerPageChange}
                onSelectPage={onSelectPage}
                userAdminSites={userAdminSites}
              />
            )}
          </Box>
        ))}
      {installAppModal.open && !isCompanyOrSiteAdmin && (
        <NonClosingModal {...installAppModalProperties} testId="install-app-modal">
          <InstallAppModalContent context={installAppModal.context} />
        </NonClosingModal>
      )}
      {editUserModal && (
        <UserModal
          context={CONTEXT.CURRENT_USER}
          data={user}
          isCurrentUser
          isEdit
          onClose={() => toggleEditUserModal(false)}
          open={editUserModal}
        />
      )}
      <UserLanguageModal />
      <UserChangePasswordModal />
      <CreateCompanyModal />
      <ChangeOryUserPasswordModal currentUserEmail={user.email} />
    </>
  );
};

UserProfile.propTypes = {
  devicesLoadedFirstTime: PropTypes.bool.isRequired,
  devicesTable: dataGridProps.isRequired,
  isCompanyOrSiteAdmin: PropTypes.bool.isRequired,
  isFirstLogin: PropTypes.bool.isRequired,
  isMarketer: PropTypes.bool,
  onLoadDevices: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSetFirstLogin: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastName: PropTypes.string,
  }).isRequired,
  userAdminSites: PropTypes.arrayOf(PropTypes.number).isRequired,
  usersCompanies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

UserProfile.defaultProps = {
  isMarketer: false,
};

const mapStateToProps = (state) => ({
  devicesLoadedFirstTime: deviceSelectors.getDevicesLoadedFirstTime(state),
  devicesTable: getDataGridSelector(state, DEVICE_MODULE, 'userProfile'),
  isCompanyOrSiteAdmin: selectors.isCompanyOrSiteAdmin(state),
  isFirstLogin: authSelectors.isFirstLogin(state),
  isMarketer: selectors.isMarketer(state),
  user: state.user.data,
  userAdminSites: selectors.getUserSitesWithAdminRole(state),
  usersCompanies: selectors.getUserCompanies(state),
});

const mapDispatchProps = {
  onLoadDevices: deviceActions.getUsersProfileDevicesRequest,
  onSearch: commonActions.search,
  onSetFirstLogin: authActions.setFirstLogin,
};

export default connect(mapStateToProps, mapDispatchProps)(UserProfile);
