import { Key, KeyOff, Pin } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../../hooks/useModal';
import { MFARecoveryCodesModal as MFARecoveryCodesModalComponent } from '../MFARecoveryCodesModal';
import { MFARecoveryCodesRefreshSessionModal as MFARecoveryCodesRefreshSessionModalComponent } from '../MFARecoveryCodesRefreshSessionModal';
import { MFASetupModal as MFASetupModalComponent } from '../MFASetupModal';
import { MFAUnlinkModal as MFAUnlinkModalComponent } from '../MFAUnlinkModal';
import messages from './messages';
import { useMFARecoveryCodesSettingsButton } from './useMFARecoveryCodesSettingsButton';
import { useMFASettingsButtons } from './useMFASettingsButtons';

export function MFASettingsButtons() {
  const { Modal: MFASetupModal, onOpen: openMFASetupModal } = useModal({
    Modal: MFASetupModalComponent,
  });
  const { Modal: MFAUnlinkModal, onOpen: openMFAUnlinkModal } = useModal({
    Modal: MFAUnlinkModalComponent,
  });

  const { Modal: MFARecoveryCodesModal, onOpen: openMFARecoveryCodesModal } = useModal({
    Modal: MFARecoveryCodesModalComponent,
  });
  const { Modal: MFARecoveryCodesSessionRefreshModal, onOpen: openSessionRefreshModal } = useModal({
    Modal: MFARecoveryCodesRefreshSessionModalComponent,
  });

  const {
    fetchMFASettings,
    isOryApiFetching: isMFASettingsInfoFetching,
    openMFAModal,
    oryMFA,
  } = useMFASettingsButtons({
    openMFASetupModal,
    openMFAUnlinkModal,
  });

  const {
    fetchMFARecoveryCodesSettings,
    isOryApiFetching: isRecoveryCodesInfoFetching,
    onCodesRegenerate,
    oryMFARecoveryCodes,
  } = useMFARecoveryCodesSettingsButton({
    openMFARecoveryCodesModal,
    openSessionRefreshModal,
  });

  useEffect(() => {
    fetchMFASettings();
  }, []);

  const isMFAEnabled = oryMFA?.isEnabled;
  return (
    <>
      <Stack gap={1} alignItems="start">
        <LoadingButton
          size="medium"
          variant="text"
          loading={isMFASettingsInfoFetching}
          loadingPosition="start"
          color={isMFAEnabled ? 'error' : 'primary'}
          startIcon={isMFAEnabled ? <KeyOff /> : <Key />}
          onClick={openMFAModal}
        >
          {isMFAEnabled ? (
            <FormattedMessage {...messages.UserInfoBoxTwoFactorAuthenticationDisable} />
          ) : (
            <FormattedMessage {...messages.UserInfoBoxTwoFactorAuthenticationEnable} />
          )}
        </LoadingButton>

        <Tooltip
          title={!isMFAEnabled ? <FormattedMessage {...messages.UserInfoBoxTwoFactorRecoveryCodesTooltip} /> : ''}
        >
          <span>
            <LoadingButton
              size="medium"
              variant="text"
              loading={isRecoveryCodesInfoFetching}
              loadingPosition="start"
              disabled={!isMFAEnabled || isMFASettingsInfoFetching}
              startIcon={<Pin />}
              onClick={fetchMFARecoveryCodesSettings}
            >
              <FormattedMessage {...messages.UserInfoBoxTwoFactorRecoveryCodes} />
            </LoadingButton>
          </span>
        </Tooltip>
      </Stack>

      <MFAUnlinkModal oryMFASettings={oryMFA!} onSuccess={fetchMFASettings} />
      <MFASetupModal
        oryMFASettings={oryMFA!}
        onSuccess={async () => {
          await fetchMFASettings();
          await fetchMFARecoveryCodesSettings();
        }}
      />
      <MFARecoveryCodesModal oryMFARecoveryCodes={oryMFARecoveryCodes!} onCodeRegenerate={onCodesRegenerate} />
      <MFARecoveryCodesSessionRefreshModal onSuccess={fetchMFARecoveryCodesSettings} />
    </>
  );
}
