import Grid from '@mui/material/Grid';
import React from 'react';

export interface IconBoxProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  withoutMargin?: boolean;
}

export function IconBox({ children, icon, withoutMargin }: IconBoxProps) {
  return (
    <Grid alignItems={!withoutMargin ? 'center' : undefined} container spacing={1} wrap="nowrap">
      <Grid item sx={!withoutMargin ? { mt: 1 } : undefined}>
        {icon}
      </Grid>
      <Grid item textAlign={'left'}>
        {children}
      </Grid>
    </Grid>
  );
}
