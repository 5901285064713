import { yupResolver } from '@hookform/resolvers/yup';
import { ResponsiveStyleValue } from '@mui/system';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { useOryUserLogin } from '../../../../ory/hooks/oryUserLogin/useOryUserLogin';
import {
  defaultValues,
  FormFieldNames,
  getMFARecoveryFormValidationScheme,
  IMFARecoveryFormData,
} from './validationSchema';

export interface IUseMFARecoveryForm {
  formSubmit: (values: IMFARecoveryFormData) => Promise<void>;
  formData: UseFormReturn<IMFARecoveryFormData, any, undefined>;
  isOryApiFetching: boolean;
  buttonsDirection: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  buttonsSpacing: number;
}

export interface IUseMFARecoveryFormProps {
  onSuccess?: () => void;
}

export function useMFARecoveryForm(props: IUseMFARecoveryFormProps): IUseMFARecoveryForm {
  const { formatMessage } = useIntl();
  const { downSm } = useBreakpoints();
  const { finishLoginWithSecondFactorRecoveryCode, isOryApiFetching } = useOryUserLogin();

  const formData = useForm<IMFARecoveryFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getMFARecoveryFormValidationScheme(formatMessage)),
  });

  const buttonsDirection = (downSm ? 'column' : 'row') as ResponsiveStyleValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;
  const buttonsSpacing = downSm ? 2 : 1;

  const formSubmit = async (values: IMFARecoveryFormData) => {
    await finishLoginWithSecondFactorRecoveryCode(
      { totpRecoveryCode: values.totpRecoveryCode },
      { fieldName: FormFieldNames.TotpRecoveryCode, formData },
      props.onSuccess
    );
  };

  return {
    buttonsDirection,
    buttonsSpacing,
    formData,
    formSubmit,
    isOryApiFetching,
  };
}
