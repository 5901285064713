import { defineMessages } from 'react-intl';

export default defineMessages({
  UserInfoBoxTwoFactorAuthenticationDisable: {
    defaultMessage: 'DISABLE TWO-FACTOR AUTHENTICATION',
    id: 'userInfoBox.twoFactorAuthentication.disable',
  },
  UserInfoBoxTwoFactorAuthenticationEnable: {
    defaultMessage: 'ENABLE TWO-FACTOR AUTHENTICATION',
    id: 'userInfoBox.twoFactorAuthentication.enable',
  },
  UserInfoBoxTwoFactorRecoveryCodes: {
    defaultMessage: 'TWO-FACTOR recovery CODES',
    id: 'userInfoBox.twoFactorRecoveryCodes',
  },
  UserInfoBoxTwoFactorRecoveryCodesTooltip: {
    defaultMessage: 'Set up two-factor authentication first',
    id: 'userInfoBox.twoFactorRecoveryCodes.tooltip',
  },
});
