import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Grid, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useClipboard } from '../../../hooks/useClipboard';
import { IOryMFARecoveryCodes } from '../../utils/MFA';
import { getCodesForClipboard, isSomeCodeUsed } from './copyCodes';
import messages from './messages';
import { styles } from './styles';

export interface IMFARecoveryCodesProps {
  oryMFARecoveryCodes: IOryMFARecoveryCodes;
  isLoading?: boolean;
}

export function MFARecoveryCodesPanel(props: IMFARecoveryCodesProps) {
  const { copyToClipboard } = useClipboard({
    copySuccessMessage: messages.TwoFactorRecoveryCodesSuccessCopy,
  });
  const { formatMessage } = useIntl();

  const codesToCopy = getCodesForClipboard(props.oryMFARecoveryCodes);
  return (
    <Box sx={styles.panelRoot}>
      <Stack spacing={4} direction={'column'} pt={1}>
        <Grid container data-testid="recovery-codes" sx={styles.recoveryCodesGrid}>
          {props.oryMFARecoveryCodes.recoveryCodes.map((code, i) => {
            return (
              <Grid item xs={6} sm={3} key={code.code + i}>
                {code.wasUsed ? (
                  <Tooltip title={formatMessage(messages.TwoFactorRecoveryCodesModalTooltipCodeUsed)}>
                    <Box sx={styles.usedCode}>{'code used'}</Box>
                  </Tooltip>
                ) : (
                  <Typography>{code.code}</Typography>
                )}
              </Grid>
            );
          })}
        </Grid>
        {!!codesToCopy.count && (
          <Button
            variant="text"
            startIcon={<ContentCopy />}
            onClick={() => {
              copyToClipboard(codesToCopy.codes);
            }}
          >
            {isSomeCodeUsed(props.oryMFARecoveryCodes)
              ? formatMessage(messages.TwoFactorRecoveryCodesModalCopyUnusedCodes)
              : formatMessage(messages.TwoFactorRecoveryCodesModalCopyAllCodes)}
          </Button>
        )}
      </Stack>
      {props.isLoading && (
        <Box sx={styles.progressWrapper}>
          <LinearProgress sx={styles.progress} />
        </Box>
      )}
    </Box>
  );
}
