export const styles = {
  panelRoot: {
    bgcolor: 'primary.create',
    border: 2,
    borderColor: 'primary.main',
    borderRadius: '4px',
    display: { sm: 'flex' },
    p: 3,
    position: 'relative',
  },
  progress: {
    bgcolor: 'primary.light !important',
  },

  progressWrapper: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
  },
  recoveryCodesGrid: {
    rowGap: 3,
    textAlign: 'center',
  },
  usedCode: {
    color: 'text.disabled',
    filter: 'blur(3px)',
    textDecoration: 'line-through',
    userSelect: 'none',
  },
};
