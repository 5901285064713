import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import messages from '../../../validations/messages';

export interface IMFARecoveryCodesFormData {
  wereRecoveryCodesCopied: boolean;
  currentPassword?: string;
}

export enum FormFieldNames {
  CurrentPassword = 'currentPassword',
  WereRecoveryCodesCopied = 'wereRecoveryCodesCopied',
}

export const defaultValues: IMFARecoveryCodesFormData = {
  wereRecoveryCodesCopied: false,
  currentPassword: undefined,
};

export function getMFARecoveryCodesFormValidationScheme(
  formatMessage: IntlFormatters['formatMessage'],
  isCurrentPasswordMandatory?: boolean,
  isRecoveryCodesCopiedMandatory?: boolean
) {
  return yup.object().shape({
    [FormFieldNames.WereRecoveryCodesCopied]: isRecoveryCodesCopiedMandatory
      ? yup.boolean().required().oneOf([true], formatMessage(messages.validationRequired))
      : yup.boolean().required(),
    [FormFieldNames.CurrentPassword]: isCurrentPasswordMandatory
      ? yup.string().required(formatMessage(messages.validationRequired))
      : yup.string(),
  });
}
