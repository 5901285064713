import { defineMessages } from 'react-intl';

export default defineMessages({
  SharedLabelCurrentPassword: {
    defaultMessage: 'Current password',
    id: 'shared.label.currentPassword',
  },
  TwoFactorRecoveryCodesModalCancel: {
    defaultMessage: 'Cancel',
    id: 'twoFactorRecoveryCodes.modal.cancel',
  },
  TwoFactorRecoveryCodesModalPasswordSubmit: {
    defaultMessage: 'Submit',
    id: 'twoFactorRecoveryCodes.modal.passwordSubmit',
  },
  TwoFactorRecoveryCodesModalSessionExpired: {
    defaultMessage: 'Your session has expired. Please, enter your password.',
    id: 'twoFactorRecoveryCodes.modal.sessionExpired',
  },
  TwoFactorRecoveryCodesModalTitle: {
    defaultMessage: 'Two-factor recovery codes',
    id: 'twoFactorRecoveryCodes.modal.title',
  },
});
