import { useDispatch } from 'react-redux';
import { IMFAFormData } from '../../../pages/LoginPage/components/MFAForm/validationScheme';
import { IMFARecoveryFormData } from '../../../pages/LoginPage/components/MFARecoveryForm/validationSchema';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { OryErrorNamedMessageId } from '../../types';
import { isSecondFactorNeeded } from '../../utils/MFA';
import { useOryLoginApi } from '../oryApi';
import { IUseOryResponseParser, IValidationField, useOryResponseParser } from '../oryResponseParser';
import { checkOrySessionAndGetCurrentUser } from './checkOrySessionAndGetCurrentUser';
import { refreshLoginForCurrentUser } from './refreshLoginForCurrentUser';

export interface IUseOryUserLogin {
  isOryApiFetching: boolean;
  loginOryUser: (values: { email: string; password: string }, onSecondFactorIsNeeded: () => void) => Promise<void>;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
  finishLoginWithSecondFactor: (
    values: { totp: string },
    validationField: IValidationField<IMFAFormData>,
    onSuccess?: () => void
  ) => Promise<void>;
  finishLoginWithSecondFactorRecoveryCode: (
    values: { totpRecoveryCode: string },
    validationField: IValidationField<IMFARecoveryFormData>,
    onSuccess?: () => void
  ) => Promise<void>;
}

export function useOryUserLogin(): IUseOryUserLogin {
  const dispatch = useDispatch();
  const { getOryCurrentUserSession, isOryApiFetching, loginToOry } = useOryLoginApi();
  const { clearFormAlertMessage, getFormAlertMessage, parseOryResponse } = useOryResponseParser();

  const loginOryUser = async (values: { email: string; password: string }, onSecondFactorIsNeeded: () => void) => {
    clearFormAlertMessage();
    const response = await loginToOry({ email: values.email, password: values.password });

    parseOryResponse({
      onError: async (error) => {
        if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_already_available])) {
          await refreshLoginForCurrentUser({
            loginProps: values,
            loginToOry,
            onError: (error) => {
              isSecondFactorNeeded(error, onSecondFactorIsNeeded);
            },
            onSuccess: async () => {
              await checkOrySessionAndGetCurrentUser({ dispatch, getOryCurrentUserSession, parseOryResponse });
            },
            parseOryResponse,
          });
        }
        isSecondFactorNeeded(error, onSecondFactorIsNeeded);
      },
      onSuccess: async () => {
        await checkOrySessionAndGetCurrentUser({ dispatch, getOryCurrentUserSession, parseOryResponse });
      },
      response,
    });
  };

  const finishLoginWithSecondFactor = async (
    values: { totp: string },
    validationField: IValidationField<IMFAFormData>,
    onSuccess?: () => void
  ) => {
    const response = await loginToOry({ totp: values.totp });

    parseOryResponse({
      onSuccess: async () => {
        await checkOrySessionAndGetCurrentUser({ dispatch, getOryCurrentUserSession, parseOryResponse });
        onSuccess && onSuccess();
      },
      response,
      validationField,
    });
  };

  const finishLoginWithSecondFactorRecoveryCode = async (
    values: { totpRecoveryCode: string },
    validationField: IValidationField<IMFARecoveryFormData>,
    onSuccess?: () => void
  ) => {
    const response = await loginToOry({ totpRecoveryCode: values.totpRecoveryCode });

    parseOryResponse({
      onSuccess: async () => {
        onSuccess && onSuccess();
        await checkOrySessionAndGetCurrentUser({ dispatch, getOryCurrentUserSession, parseOryResponse });
      },
      response,
      validationField,
    });
  };

  return {
    finishLoginWithSecondFactor,
    finishLoginWithSecondFactorRecoveryCode,
    getFormAlertMessage,
    isOryApiFetching,
    loginOryUser,
  };
}
