import { Check } from '@mui/icons-material';
import { MultistepDialog } from '@my2n/multistep-dialog';
import React from 'react';
import { useIntl } from 'react-intl';
import { IModalBase } from '../../../modules/commonTypes';
import { IOryMFASettings } from '../../utils/MFA';
import messages from './messages';
import { MFASetupStep1 } from './steps/step1';
import { MFASetupStep2 } from './steps/step2';
import { useMFASetupModal } from './useMFASetupModal';
import { IMFASetupFormData } from './validationSchema';

export interface IChangeOryUserPasswordModalProps extends IModalBase {
  oryMFASettings: IOryMFASettings;
  onSuccess: () => void;
}

export function MFASetupModal({ onClose, onSuccess, open, oryMFASettings }: IChangeOryUserPasswordModalProps) {
  const { formData, getFormAlertMessage, isOryApiFetching, isSessionRefreshRequired, setupMFA } = useMFASetupModal({
    onSessionError: onClose,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
  });
  const { formatMessage } = useIntl();

  const submitForm = async (values: IMFASetupFormData) => {
    await setupMFA({
      csrfToken: oryMFASettings.csrfToken,
      currentPassword: values.currentPassword,
      flowId: oryMFASettings.flowId,
      totpValue: values.totp,
    });
  };

  return (
    <MultistepDialog
      title={formatMessage(messages.TwoFactorAuthenticationModalLinkTitle)}
      backButtonLabel={formatMessage(messages.TwoFactorAuthenticationModalLinkBack)}
      nextButtonLabel={formatMessage(messages.TwoFactorAuthenticationModalLinkNext)}
      open={open}
      minContentHeight={300}
      cancelButtonLabel={formatMessage(messages.TwoFactorAuthenticationModalLinkCancel)}
      onClose={onClose}
      onSubmit={formData.handleSubmit(submitForm)}
      submitButton={{
        icon: <Check />,
        isDisabled: isOryApiFetching || !formData.formState.isValid,
        label: formatMessage(messages.TwoFactorAuthenticationModalLinkConfirm),
      }}
      orderedSteps={[
        {
          children: <MFASetupStep1 />,
        },
        {
          children: (
            <MFASetupStep2
              oryMFASettings={oryMFASettings}
              formData={formData}
              getFormAlertMessage={getFormAlertMessage}
              isSessionRefreshRequired={isSessionRefreshRequired}
              submitForm={submitForm}
            />
          ),
        },
      ]}
    />
  );
}
