import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import messages from '../../../../validations/messages';

export interface IMFARecoveryFormData {
  totpRecoveryCode: string;
}

export enum FormFieldNames {
  TotpRecoveryCode = 'totpRecoveryCode',
}

export const defaultValues: IMFARecoveryFormData = {
  totpRecoveryCode: '',
};

export function getMFARecoveryFormValidationScheme(formatMessage: IntlFormatters['formatMessage']) {
  return yup.object().shape({
    [FormFieldNames.TotpRecoveryCode]: yup.string().required(formatMessage(messages.validationRequired)),
  });
}
