import { IOryMFARecoveryCodes } from '../../utils/MFA';

export function getCodesForClipboard(oryMFARecoveryCodes: IOryMFARecoveryCodes): { count: number; codes: string } {
  const codes = oryMFARecoveryCodes.recoveryCodes.filter((code) => !code.wasUsed).map((code) => code.code);
  return {
    codes: codes.join(' '),
    count: codes.length,
  };
}

export function isSomeCodeUsed(oryMFARecoveryCodes: IOryMFARecoveryCodes): boolean {
  return oryMFARecoveryCodes.recoveryCodes.some((code) => code.wasUsed);
}
