import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CompanyAdminDevicesDataGrid, Title, UserModal } from '../../components';
import UserChangePasswordDialog from '../../components/UserChangePasswordDialog';
import { useModal } from '../../hooks/useModal';
import deviceActions from '../../modules/devices';
import { CONTEXT } from '../../modules/user';
import { ChangeOryUserPasswordModal as ChangeOryUserPasswordModalComponent } from '../../ory/components/ChangeOryPasswordModal';
import { useOryChangeProfileModalOpener } from '../../ory/hooks/oryChangeProfile';
import { useFetchAdminDetailData } from './hooks/fetchAdminDetailData';
import { useGetAdminDetailData } from './hooks/useGetAdminDetailData';
import messages from './messages';
import { UserInfoBox } from '../../components/UserInfoBox';

const CompanyAdminDetail = () => {
  const dispatch = useDispatch();
  const adminDetailData = useGetAdminDetailData();

  useFetchAdminDetailData(adminDetailData.currentCompanyId, adminDetailData.currentUserId, adminDetailData.isFetching);

  const { Modal: EditUserModal, onOpen: onOpenEditUserModal } = useModal({
    Modal: UserModal,
  });

  const { Modal: UserChangePasswordModal, onOpen: onOpenUserChangePasswordModal } = useModal({
    Modal: UserChangePasswordDialog,
  });

  const { Modal: ChangeOryUserPasswordModal, onOpen: openChangeOryUserPasswordModal } = useModal({
    Modal: ChangeOryUserPasswordModalComponent,
  });

  const { openChangeProfileModal: openChangePasswordModal } = useOryChangeProfileModalOpener({
    openModalForMy2nUser: onOpenUserChangePasswordModal,
    openModalForOryUser: openChangeOryUserPasswordModal,
  });

  const isCurrentUser = adminDetailData.currentUserId === adminDetailData.currentUser?.id;

  if (adminDetailData.isFetching || adminDetailData.userDetail === undefined) {
    return <>{false}</>;
  }

  return (
    <>
      {adminDetailData.currentUser?.id !== undefined && (
        <>
          <Title heading={<FormattedMessage {...messages.companyAdminDetailTitle} />} />
          <UserInfoBox
            canChangePassword={isCurrentUser}
            loading={adminDetailData.isFetching}
            onShowEditPasswordModal={openChangePasswordModal}
            onShowEditUserModal={onOpenEditUserModal}
            user={adminDetailData.userDetail}
          />
          <Box sx={{ mx: 0, my: 4 }}>
            {adminDetailData.currentUserId !== undefined && (
              <CompanyAdminDevicesDataGrid
                {...adminDetailData.devicesTable}
                companyId={adminDetailData.currentCompanyId}
                onLoadDevices={(companyId, siteId, groupId, userId, page, rowsPerPage, filter, order) =>
                  dispatch(
                    deviceActions.getCompanyAdminDevicesListRequest(
                      companyId,
                      siteId,
                      groupId,
                      userId,
                      page,
                      rowsPerPage,
                      filter,
                      order
                    )
                  )
                }
                userId={adminDetailData.currentUserId}
              />
            )}
          </Box>
          <EditUserModal
            context={CONTEXT.COMPANY_ADMINS}
            data={adminDetailData.userDetail}
            isCurrentUser={isCurrentUser}
            isEdit
            showApartmentField={false}
          />
          <UserChangePasswordModal />
          <ChangeOryUserPasswordModal currentUserEmail={adminDetailData.currentUser.email} />
        </>
      )}
    </>
  );
};

export default CompanyAdminDetail;
