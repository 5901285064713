import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconBox } from '../../../components/IconBox';
import messages from './messages';

function ImportResultFailed({ importStats, inline }) {
  const abortedMessage = (
    <FormattedMessage
      {...messages.siteUsersImportResultStepWereCanceledAtRow}
      values={{
        count: importStats.aborted,
        rowNumber: importStats.abortedAtRow,
        strong: (chunks) => (
          <Box component="span" sx={{ color: 'error.main', fontWeight: 'bold' }}>
            {chunks}
          </Box>
        ),
      }}
    />
  );
  return (
    <>
      {importStats.imported > 0 && (
        <IconBox icon={<CheckCircle color="success" />}>
          <Typography>
            <FormattedMessage
              {...messages.siteUsersImportResultStepUsersImported}
              values={{
                count: importStats.imported,
                strong: (chunks) => (
                  <Box component="span" sx={{ color: 'success.main', fontWeight: 'bold' }}>
                    {chunks}
                  </Box>
                ),
              }}
            />
          </Typography>
        </IconBox>
      )}

      {(importStats.failed > 0 || importStats.aborted > 0) && (
        <>
          <IconBox icon={<Cancel color="error" />}>
            <Typography>
              {importStats.failed > 0 && (
                <FormattedMessage
                  {...messages.siteUsersImportResultStepRecordsCouldNotBeImported}
                  values={{
                    count: importStats.failed,
                    strong: (chunks) => (
                      <Box component="span" sx={{ color: 'error.main', fontWeight: 'bold' }}>
                        {chunks}
                      </Box>
                    ),
                  }}
                />
              )}
              {importStats.failed > 0 && importStats.aborted > 0 && ', '}
              {importStats.failed > 0 && importStats.aborted > 0 && !inline && <br />}
              {importStats.aborted > 0 && abortedMessage}
            </Typography>
          </IconBox>
        </>
      )}
    </>
  );
}

ImportResultFailed.propTypes = {
  importStats: PropTypes.object.isRequired,
  inline: PropTypes.bool,
};

ImportResultFailed.defaultProps = {
  inline: false,
};

export default ImportResultFailed;
