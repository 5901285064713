import { defineMessages } from 'react-intl';

export default defineMessages({
  TwoFactorRecoveryCodesModalCopyAllCodes: {
    defaultMessage: 'COPY ALL CODES',
    id: 'twoFactorRecoveryCodes.modal.copyAllCodes',
  },
  TwoFactorRecoveryCodesModalCopyUnusedCodes: {
    defaultMessage: 'COPY UNUSED CODES',
    id: 'twoFactorRecoveryCodes.modal.copyUnusedCodes',
  },
  TwoFactorRecoveryCodesModalTooltipCodeUsed: {
    defaultMessage: 'Code already used',
    id: 'twoFactorRecoveryCodes.modal.tooltip.codeUsed',
  },
  TwoFactorRecoveryCodesSuccessCopy: {
    defaultMessage: 'Codes copied',
    id: 'twoFactorRecoveryCodes.success.copy',
  },
});
