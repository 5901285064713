import { IActionButtonData } from '@2n/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { addSnackbarSuccess } from '../../../modules/notifications/actions';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { useOrySettingsApi } from '../../hooks/oryApi';
import {
  IConfirmRecoveryCodesParams,
  IDisableMFARecoveryCodesParams,
  IRegenerateRecoveryCodesParams,
} from '../../hooks/oryApi/settings/settingsApi';
import { useOryUserPrivilegedSessionRefresh } from '../../hooks/oryPrivilegedSession/useOryUserPrivilegedSessionRefresh';
import { IUseOryResponseParser, useOryResponseParser } from '../../hooks/oryResponseParser';
import { OryContext, OryErrorNamedMessageId } from '../../types';
import { IOryMFARecoveryCodes } from '../../utils/MFA';
import messages from './messages';
import {
  defaultValues,
  FormFieldNames,
  getMFARecoveryCodesFormValidationScheme,
  IMFARecoveryCodesFormData,
} from './validationSchema';
export interface IUseOryMFAUnlink {
  formData: UseFormReturn<IMFARecoveryCodesFormData, any, undefined>;
  primaryButtonSettings: IMFARecoveryCodeModalButton | undefined;
  secondaryButtonSettings: Omit<IMFARecoveryCodeModalButton, 'action' | 'isLoading'>;
  ternaryButtonSettings: IMFARecoveryCodeModalButton | undefined;
  isConfirmationCheckboxVisible: boolean;
  isOryApiFetching: boolean;
  isSessionRefreshRequired: boolean;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
  isModalClosable: boolean;
}

export interface IUseOryMFASetupProps {
  oryMFARecoveryCodes: IOryMFARecoveryCodes;
  onCodeRegenerate: (newConfiguration: IOryMFARecoveryCodes) => void;
  onSuccess: () => void;
}

export interface IMFARecoveryCodeModalButton {
  label: string;
  action: (values: IMFARecoveryCodesFormData) => Promise<void> | void;
  variant?: IActionButtonData['variant'];
  icon?: 'check' | 'refresh';
  isLoading?: boolean;
}

export function useMFARecoveryCodesModal(props: IUseOryMFASetupProps): IUseOryMFAUnlink {
  const { confirmRecoveryCodes, disableRecoveryCodes, isOryApiFetching, regenerateRecoveryCodes } = useOrySettingsApi();
  const { clearFormAlertMessage, getFormAlertMessage, parseOryResponse, parseOryResponseWithData } =
    useOryResponseParser();

  const [isSessionRefreshRequired, setIsSessionRefreshRequired] = useState(false);
  const [isDisableRecoveryCodesFetching, setIsDisableRecoveryCodesFetching] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const formData = useForm<IMFARecoveryCodesFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(
      getMFARecoveryCodesFormValidationScheme(
        formatMessage,
        isSessionRefreshRequired,
        props.oryMFARecoveryCodes.isConfirmAvailable
      )
    ),
  });
  const { isOryApiFetching: isOryLoginApiFetching, refreshSession } = useOryUserPrivilegedSessionRefresh({
    context: OryContext.MFASettings,
    validationField: { fieldName: FormFieldNames.CurrentPassword, formData },
  });

  const submitForm = (values: IMFARecoveryCodesFormData) => {
    if (props.oryMFARecoveryCodes.isConfirmAvailable) {
      confirmCodes({
        csrfToken: props.oryMFARecoveryCodes.csrfToken,
        currentPassword: values.currentPassword,
        flowId: props.oryMFARecoveryCodes.flowId,
      });
    }
    if (props.oryMFARecoveryCodes.isRegenerateAvailable) {
      regenerateCodes({
        csrfToken: props.oryMFARecoveryCodes.csrfToken,
        currentPassword: values.currentPassword,
        flowId: props.oryMFARecoveryCodes.flowId,
      });
    }
  };

  const regenerateCodes = async (values: IRegenerateRecoveryCodesParams) => {
    clearFormAlertMessage();
    if (isSessionRefreshRequired && values.currentPassword) {
      const result = await refreshSession(values.currentPassword);
      if (!result) {
        return;
      }
      setIsSessionRefreshRequired(false);
    }

    const response = await regenerateRecoveryCodes({
      csrfToken: values.csrfToken,
      flowId: values.flowId,
    });

    parseOryResponseWithData({
      onError: (error) => {
        if (error) {
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
            navigate(PATHS.LOGOUT);
          }
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
            setIsSessionRefreshRequired(true);
          }
        }
      },
      onSuccess: (data) => {
        props.onCodeRegenerate(data);
      },
      response,
    });
  };

  const confirmCodes = async (values: IConfirmRecoveryCodesParams) => {
    clearFormAlertMessage();
    if (isSessionRefreshRequired && values.currentPassword) {
      const result = await refreshSession(values.currentPassword);
      if (!result) {
        return;
      }
      setIsSessionRefreshRequired(false);
    }

    const response = await confirmRecoveryCodes({
      csrfToken: values.csrfToken,
      flowId: values.flowId,
    });

    parseOryResponse({
      onError: (error) => {
        if (error) {
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
            navigate(PATHS.LOGOUT);
          }
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
            setIsSessionRefreshRequired(true);
          }
        }
      },
      onSuccess: () => {
        dispatch(addSnackbarSuccess(messages.TwoFactorRecoveryCodesSuccessGenerate));
        props.onSuccess();
      },
      response,
    });
  };

  const disableCodes = async (values: IDisableMFARecoveryCodesParams) => {
    setIsDisableRecoveryCodesFetching(true);
    clearFormAlertMessage();
    if (isSessionRefreshRequired && values.currentPassword) {
      const result = await refreshSession(values.currentPassword);
      setIsDisableRecoveryCodesFetching(false);
      if (!result) {
        return;
      }
      setIsSessionRefreshRequired(false);
    }

    const response = await disableRecoveryCodes({
      csrfToken: values.csrfToken,
      flowId: values.flowId,
    });

    parseOryResponse({
      onError: (error) => {
        setIsDisableRecoveryCodesFetching(false);
        if (error) {
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
            navigate(PATHS.LOGOUT);
          }
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
            setIsSessionRefreshRequired(true);
          }
        }
      },
      onSuccess: () => {
        dispatch(addSnackbarSuccess(messages.TwoFactorRecoveryCodesSuccessDelete));
        props.onSuccess();
        setIsDisableRecoveryCodesFetching(false);
      },
      response,
    });
  };

  const getPrimaryButtonSettings = (): IMFARecoveryCodeModalButton | undefined => {
    const isLoading = isOryApiFetching && !isDisableRecoveryCodesFetching;
    if (props.oryMFARecoveryCodes.isConfirmAvailable) {
      return {
        action: submitForm,
        icon: 'check',
        isLoading,
        label: formatMessage(messages.TwoFactorRecoveryCodesModalConfirmCodes),
      };
    }
    if (props.oryMFARecoveryCodes.isRegenerateAvailable) {
      return {
        action: submitForm,
        icon: 'refresh',
        isLoading,
        label: formatMessage(messages.TwoFactorRecoveryCodesModalGenerateCodes),
        variant: 'text',
      };
    }

    return undefined;
  };

  const getSecondaryButtonSettings = (): Omit<IMFARecoveryCodeModalButton, 'action'> => {
    if (props.oryMFARecoveryCodes.isConfirmAvailable && !props.oryMFARecoveryCodes.hasBeenRegenerated) {
      return { label: formatMessage(messages.TwoFactorRecoveryCodesModalDontUseCodes) };
    }
    return { label: formatMessage(messages.TwoFactorRecoveryCodesModalCancel) };
  };

  const getTernaryButtonSettings = (): IMFARecoveryCodeModalButton | undefined => {
    //TODO disable and regenerate are probably the same options from logical perspective
    if (props.oryMFARecoveryCodes.isDisableAvailable) {
      return {
        action: async (values: IMFARecoveryCodesFormData) => {
          await disableCodes({
            csrfToken: props.oryMFARecoveryCodes.csrfToken,
            currentPassword: values.currentPassword,
            flowId: props.oryMFARecoveryCodes.flowId,
          });
        },
        isLoading: isDisableRecoveryCodesFetching,
        label: formatMessage(messages.TwoFactorRecoveryCodesModalDeleteCodes),
        variant: 'text',
      };
    }
    return undefined;
  };

  return {
    formData,
    getFormAlertMessage,
    isConfirmationCheckboxVisible: props.oryMFARecoveryCodes.isConfirmAvailable,
    isModalClosable: !props.oryMFARecoveryCodes.isRegenerateAvailable,
    isOryApiFetching: isOryApiFetching || isOryLoginApiFetching,
    isSessionRefreshRequired,
    primaryButtonSettings: getPrimaryButtonSettings(),
    secondaryButtonSettings: getSecondaryButtonSettings(),
    ternaryButtonSettings: getTernaryButtonSettings(),
  };
}
