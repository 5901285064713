import { Autorenew, Check } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModal } from '../../../components/_DesignSystemWrappers/Modal';
import { PasswordInput } from '../../../components/HookFormFields/PasswordInput';
import { IModalBase } from '../../../modules/commonTypes';
import { IOryMFARecoveryCodes } from '../../utils/MFA';
import { OryErrorBar } from '../OryErrorBar';
import messages from './messages';
import { useMFARecoveryCodesModal } from './useMFARecoverCodesModal';
import { FormFieldNames } from './validationSchema';
import { MFARecoveryCodesPanel } from '../MFARecoveryCodesPanel/MFARecoveryCodesPanel';
import Checkbox from '../../../components/HookFormFields/Checkbox';
import { FilledInfoBar } from '../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';

export interface IChangeOryUserPasswordModalProps extends IModalBase {
  oryMFARecoveryCodes: IOryMFARecoveryCodes;
  onCodeRegenerate: (newConfiguration: IOryMFARecoveryCodes) => void;
}

export function MFARecoveryCodesModal({
  onClose,
  open,
  oryMFARecoveryCodes,
  onCodeRegenerate,
}: IChangeOryUserPasswordModalProps) {
  const { formatMessage } = useIntl();
  const {
    formData,
    getFormAlertMessage,
    isOryApiFetching,
    isSessionRefreshRequired,
    primaryButtonSettings,
    isConfirmationCheckboxVisible,
    secondaryButtonSettings,
    ternaryButtonSettings,
    isModalClosable,
  } = useMFARecoveryCodesModal({
    onSuccess: onClose,
    oryMFARecoveryCodes,
    onCodeRegenerate,
  });

  const getPrimaryButtonIcon = () => {
    if (primaryButtonSettings?.icon === 'check') {
      return <Check />;
    }
    if (primaryButtonSettings?.icon === 'refresh') {
      return <Autorenew />;
    }
  };

  return (
    <ActionModal
      icon={getPrimaryButtonIcon()}
      isNotClosable={isModalClosable}
      isPrimaryButtonDisabled={isOryApiFetching || !formData.formState.isValid || !primaryButtonSettings}
      onClose={onClose}
      open={open}
      primaryButtonAction={formData.handleSubmit(primaryButtonSettings!.action)}
      primaryButtonText={primaryButtonSettings?.label}
      primaryButtonVariant={primaryButtonSettings?.variant}
      isPrimaryButtonLoading={primaryButtonSettings?.isLoading}
      secondaryButtonText={secondaryButtonSettings.label}
      title={formatMessage(messages.TwoFactorRecoveryCodesModalTitle)}
      ternaryButton={
        ternaryButtonSettings
          ? {
              text: ternaryButtonSettings.label,
              action: formData.handleSubmit(ternaryButtonSettings.action),
              variant: ternaryButtonSettings.variant,
              color: 'error',
              isDisabled: isOryApiFetching || !formData.formState.isValid,
              isLoading: ternaryButtonSettings.isLoading ,
            }
          : undefined
      }
    >
      <Stack gap={2} sx={{ width: '100%' }}>
        <Typography>
          <FormattedMessage {...messages.TwoFactorRecoveryCodesModalText1}></FormattedMessage>
        </Typography>

        {isConfirmationCheckboxVisible && (
          <Typography>
            <FormattedMessage {...messages.TwoFactorRecoveryCodesModalText2}></FormattedMessage>
          </Typography>
        )}
        <MFARecoveryCodesPanel oryMFARecoveryCodes={oryMFARecoveryCodes} isLoading={isOryApiFetching} />

        <FormProvider {...formData}>
          <form onSubmit={primaryButtonSettings && formData.handleSubmit(primaryButtonSettings.action)}>
            {isConfirmationCheckboxVisible && (
              <Checkbox
                required
                label={formatMessage(messages.TwoFactorRecoveryCodesModalCheckbox)}
                name={FormFieldNames.WereRecoveryCodesCopied}
              ></Checkbox>
            )}
            {!isSessionRefreshRequired && (
              <FilledInfoBar>
                <Typography variant="body2" whiteSpace={'pre'}>
                  <FormattedMessage {...messages.TwoFactorRecoveryCodesModalInfoAlert}></FormattedMessage>
                </Typography>
              </FilledInfoBar>
            )}

            {isSessionRefreshRequired && (
              <Stack gap={2}>
                <OryErrorBar error={getFormAlertMessage()} isWithoutMarginBottom />
                <PasswordInput
                  id="current-password"
                  autoFocus
                  label={formatMessage(messages.SharedLabelCurrentPassword)}
                  name={FormFieldNames.CurrentPassword}
                  required
                  fullWidth
                />
              </Stack>
            )}
          </form>
        </FormProvider>
      </Stack>
    </ActionModal>
  );
}
