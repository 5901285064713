import { Check } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModal } from '../../../components/_DesignSystemWrappers/Modal';
import { PasswordInput } from '../../../components/HookFormFields/PasswordInput';
import { IModalBase } from '../../../modules/commonTypes';
import messages from './messages';
import { useMFARecoveryCodesRefreshSessionModal } from './useMFARecoveryCodesRefreshSessionModal';
import { FormFieldNames, IMFARecoveryCodesRereshSessionFormData } from './validationSchema';
import { FilledErrorBar } from '../../../components/_DesignSystemWrappers/ErrorBar/FilledErrorBar';

export interface IChangeOryUserPasswordModalProps extends IModalBase {
  onSuccess: () => void;
}

export function MFARecoveryCodesRefreshSessionModal({ onClose, onSuccess, open }: IChangeOryUserPasswordModalProps) {
  const { formatMessage } = useIntl();
  const { formData, isOryApiFetching, refreshOrySession } = useMFARecoveryCodesRefreshSessionModal({
    onSuccess: () => {
      onSuccess();
      onClose();
    },
  });

  const submitForm = async (values: IMFARecoveryCodesRereshSessionFormData) => {
    if (values.currentPassword) {
      await refreshOrySession({
        currentPassword: values.currentPassword,
      });
    }
  };

  return (
    <ActionModal
      icon={<Check />}
      isPrimaryButtonDisabled={isOryApiFetching || !formData.formState.isValid}
      onClose={onClose}
      open={open}
      primaryButtonAction={formData.handleSubmit(submitForm)}
      primaryButtonText={formatMessage(messages.TwoFactorRecoveryCodesModalPasswordSubmit)}
      secondaryButtonText={formatMessage(messages.TwoFactorRecoveryCodesModalCancel)}
      title={formatMessage(messages.TwoFactorRecoveryCodesModalTitle)}
    >
      <FormProvider {...formData}>
        <form onSubmit={formData.handleSubmit(submitForm)}>
          <Stack gap={2}>
            <FilledErrorBar>
              <FormattedMessage {...messages.TwoFactorRecoveryCodesModalSessionExpired} />
            </FilledErrorBar>
            <PasswordInput
              id="current-password"
              label={formatMessage(messages.SharedLabelCurrentPassword)}
              name={FormFieldNames.CurrentPassword}
              required
              autoFocus
              fullWidth
            />
          </Stack>
        </form>
      </FormProvider>
    </ActionModal>
  );
}
