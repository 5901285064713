import { UpdateLoginFlowBody } from '@ory/client';
import { ILoginToOryProps } from '../hooks/oryApi';

export function getLoginFlowUpdateBody(params: ILoginToOryProps, csrfToken: string): UpdateLoginFlowBody {
  if (params.totp) {
    return {
      csrf_token: csrfToken,
      method: 'totp',
      totp_code: params.totp,
    };
  }

  if (params.totpRecoveryCode) {
    return {
      csrf_token: csrfToken,
      lookup_secret: params.totpRecoveryCode,
      method: 'lookup_secret',
    };
  }

  return {
    csrf_token: csrfToken,
    identifier: params.email!,
    method: 'password',
    password: params.password!,
  };
}
